<template>
  <div class="flex flex-col justify-center xl:px-6 px-2 py-2 min-h-full h-auto">
    <div class="flex justify-center">
      <p class="font-inter font-bold text-2xl m-2 mb-4 text-gray-900">
        Prijavite kvar Vašeg uređaja
      </p>
      <hr />
    </div>
    <div
      class="
        xl:w-2/3
        xl:h-4/5
        md:w-3/4
        w-full
        min-h-full
        h-auto
        xl:p-9
        bg-gray-50
        rounded-3xl
        self-center
        relative
      "
    >
      <div class="w-full h-full">
        <form
          class="grid grid-flow-row grid-cols-2 xl:p-20 p-5 gap-4"
          id="kvar-form"
          @submit.prevent="onSubmit"
        >
          <div class="col-span-2 xl:col-span-1 w-full">
            <p class="font-thin text-gray-900 text-lg">Puno ime*</p>
            <input
              v-model="ime"
              type="text"
              name="ime"
              id="ime"
              placeholder="Ime i Prezime"
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
              required
            />
          </div>

          <div class="col-span-2 xl:col-span-1 w-full">
            <p class="font-thin text-gray-900 text-lg">Adresa*</p>
            <input
              v-model="adresa"
              type="text"
              name="adresa"
              id="adresa"
              placeholder="Obrež Zelinski 17, Sveti Ivan Zelina"
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
              required
            />
          </div>

          <div class="col-span-2 xl:col-span-1 w-full">
            <p class="font-thin text-gray-900 text-lg">Telefon*</p>
            <input
              v-model="telefon"
              type="tel"
              name="telefon"
              id="telefon"
              placeholder="+38598123456"
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
              required
            />
          </div>

          <div class="col-span-2 xl:col-span-1 w-full">
            <p class="font-thin text-gray-900 text-lg">E-Mail</p>
            <input
              v-model="email"
              type="email"
              name="email"
              id="email"
              placeholder="servis@esip.hr"
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
            />
          </div>

          <div class="col-span-2 xl:col-span-1 w-full">
            <p class="font-thin text-gray-900 text-lg">Vrsta aparata*</p>
            <select
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
              v-model="vrsta_aparata1"
              @change="changed_vrsta"
              name="vrsta_aparata1"
              id="vrsta_aparata1"
              :required="show_vrsta"
            >
              <option value="perilica_rublja">Perilica rublja</option>
              <option value="susilica_rublja">Sušilica rublje</option>
              <option value="perilica_posuda">Perilica posuđa</option>
              <option value="zamrzivac">Zamrzivač</option>
              <option value="hladnjak">Hladnjak</option>
              <option value="ostalo">Ostalo</option>
            </select>
          </div>

          <div
            class="col-span-2 xl:col-span-1 w-full"
            :class="{ hidden: !show_vrsta }"
          >
            <p class="font-thin text-gray-900 text-lg">
              Unesite vrstu aparata*
            </p>
            <input
              type="text"
              name="vrsta_aparata2"
              id="vrsta_aparata2"
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
              :required="show_proizvodac"
            />
          </div>

          <div class="col-span-2 xl:col-span-1 w-full">
            <p class="font-thin text-gray-900 text-lg">Proizvođač*</p>
            <select
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
              v-model="proizvodac1"
              @change="changed_proizvodac"
              name="proizvodac1"
              id="proizvodac1"
            >
              <option value="electrolux">Electrolux</option>
              <option value="zanussi">ZANUSSI</option>
              <option value="aeg">AEG</option>
              <option value="gorenje">Gorenje</option>
              <option value="koncar">Končar</option>
              <option value="ikea">IKEA</option>
              <option value="zoppas">Zoppas</option>
              <option value="zanker">ZANKER</option>
              <option value="progress">PROGRESS</option>
              <option value="juno">Juno</option>
              <option value="privileg">Privileg</option>
              <option value="ostalo">Ostalo</option>
            </select>
          </div>

          <div
            class="col-span-2 xl:col-span-1 w-full"
            :class="{ hidden: !show_proizvodac }"
          >
            <p class="font-thin text-gray-900 text-lg">Unesite proizvođača*</p>
            <input
              type="text"
              name="proizvodac2"
              id="proizvodac2"
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
            />
          </div>

          <div class="col-span-2 xl:col-span-1 w-full">
            <p class="font-thin text-gray-900 text-lg">Pod garancijom*</p>
            <select
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-2/3
              "
              name="garancija"
              id="garancija"
              required
            >
              <option value="da">DA</option>
              <option value="ne">NE</option>
              <option value="neznam">Ne znam</option>
            </select>
          </div>

          <div class="col-span-2 w-full">
            <p class="font-thin text-gray-900 text-lg">Opis kvara*</p>
            <textarea
              v-model="opis"
              class="
                bg-gray-200
                focus:ring-gray-900
                focus:bg-white
                focus:shadow-md
                border-none
                rounded-lg
                w-full
                xl:w-5/6
              "
              name="opis"
              id="opis"
              cols="30"
              rows="10"
              required
            ></textarea>
          </div>

          <recaptcha ref="recaptcha" @verify="submit"></recaptcha>

          <div class="col-span-2 w-full">
            <button
              :class="{ 'cursor-not-allowed': prijavljeno }"
              class="btn-secondary-front"
              type="submit"
              :disabled="prijavljeno"
            >
              Prijavi
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Dialog ref="dialog_success" button="OK" title="Kvar prijavljen!">
    <p>Kvar Vašeg uređaja je uspješno prijavljen.</p>
    <p>Uskoro ćemo Vas kontaktirati.</p>
  </Dialog>
  <Dialog ref="dialog_fail" button="OK" title="Kvar nije uspješno prijavljen!">
    <p>Nešto nije u redu.</p>
    <p>Molimo Vas pokušajte ponovno.</p>
  </Dialog>
  <Dialog ref="dialog_submiting" title="Prijavljivanje kvara u tijeku...">
    <p>Molimo prićekajte trenutak.</p>
  </Dialog>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
import Dialog from "@/components/Dialog.vue";
import axios from "axios";

var url = `https://${process.env.VUE_APP_BASE_URL}/api/v1/mail/prijavi-kvar`;

export default {
  components: {
    Recaptcha,
    Dialog,
  },

  data() {
    return {
      show_vrsta: false,
      show_proizvodac: false,
      ime: "",
      adresa: "",
      telefon: "",
      email: "",
      vrsta_aparata1: "",
      vrsta_aparata2: "",
      proizvodac1: "",
      proizvodac2: "",
      garancija: "",
      opis: "",
      rcapt_sig_key: "6LcEJwwbAAAAAGwR1tjcTvGwtO01OG3FDXLwD46y",
      prijavljeno: false,
      p: false,
    };
  },

  methods: {
    changed_vrsta() {
      this.show_vrsta = this.vrsta_aparata1 == "ostalo";
    },
    changed_proizvodac() {
      this.show_proizvodac = this.proizvodac1 == "ostalo";
    },
    onSubmit() {
      this.$refs.dialog_submiting.openModal();
      this.executeRecaptcha();
    },

    async submit(response) {
      axios({
        url: url,
        data: {
          form_data: {
            ime: this.ime,
            adresa: this.adresa,
            telefon: this.telefon,
            email: this.email,
            vrsta_aparata: this.vrsta_aparata1
              ? this.vrsta_aparata1
              : this.vrsta_aparata2,
            proizvodac: this.proizvodac1 ? this.proizvodac1 : this.proizvodac2,
            garancija: this.garancija,
            opis: this.opis,
          },
          recaptcha_response: response,
        },
        method: "POST",
      })
        .then((response) => {
          this.prijavljeno = true;
          this.$refs.dialog_submiting.closeModal();
          this.$refs.dialog_success.openModal();
        })
        .catch((error) => {
          this.$refs.dialog_submiting.closeModal();
          this.$refs.dialog_fail.openModal();
        });
    },
    // execute the recaptcha widget
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>