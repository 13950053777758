<template>
  <div
    class="
      flex flex-col
      justify-center
      xl:px-6
      px-2
      py-2
      xl:min-h-800
      min-h-600
      h-full
    "
  >
    <div
      class="
        xl:w-2/3
        xl:h-4/5
        xl:min-h-600
        md:w-3/4
        min-h-400
        w-full
        h-full
        border-8 border-none
        rounded-3xl
        bg-gradient-to-br
        from-gray-800
        to-black
        self-center
        relative
      "
    >
      <div
        class="
          absolute
          xl:w-1/3
          lg:w-2/3
          h-full
          flex flex-col
          p-10
          xl:ml-32
          md:ml-10
        "
      >
        <h1 class="text-white font-thin text-2xl">
          Elektroservis Panjkrc
          <!--<small class="text-sm">vl. Ivica Panjkrc</small> -->
        </h1>
        <hr />
        <h2 class="text-white font-inter font-bold text-lg">
          Servis kućanskih aparata
        </h2>
      </div>
      <div
        class="
          flex flex-col
          h-full
          w-full
          p-9
          xl:ml-32
          md:ml-10
          justify-center
          space-y-5
        "
      >
        <h2
          class="
            xl:text-7xl
            lg:text-6xl
            text-5xl text-white
            font-inter font-bold
          "
        >
          On-line
        </h2>
        <h2
          class="
            xl:text-7xl
            lg:text-6xl
            text-5xl text-white
            font-inter font-bold
          "
        >
          Prijava kvara
        </h2>
        <div class="flex flex-row xl:space-x-8 space-x-3 z-10">
          <router-link to="/prijava-kvara">
            <button class="btn-primary-front">Prijavite kvar</button>
          </router-link>
          <router-link to="/kontakt">
            <button class="btn-secondary-front">Nazovite nas</button>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="self-center xl:mt-12 mt-8 text-4xl"
      @click="gotosection('second_section')"
    >
      <i class="fas fa-arrow-down animate-bounce"></i>
    </div>
  </div>

  <div
    class="
      flex flex-col
      justify-center
      align-middle
      xl:px-6
      px-2
      xl:py-20
      py-10
      h-auto
      min-h-full
      bg-gradient-to-br
      from-gray-800
      to-black
    "
    id="second_section"
  >
    <div class="flex flex-col justify-center items-center w-full gap-10">
      <h2
        class="
          text-white
          xl:text-5xl
          lg:text-3xl
          text-2xl
          font-inter font-bold
          relative
        "
      >
        Zašto odabrati nas
      </h2>
      <div
        class="
          grid grid-cols-1
          lg:grid-cols-2
          xl:w-2/3
          md:w-3/4
          w-full
          self-center
          text-center
          xl:gap-10
          gap-5
        "
      >
        <div class="item-box xl:m-5 h-80">
          <div
            class="
              self-center
              flex flex-col
              justify-center
              items-center
              gap-2
              xl:p-10
            "
          >
            <h3 class="font-inter font-bold text-5xl">30+</h3>

            <h4 class="font-inter font-bold 2xl:text-3xl xl:text-2xl text-xl">
              Godina iskustva
            </h4>
            <hr class="border-gray-900 w-full" />
            <p>
              Imamo više od 30 godina iskustva servisiranja i prodaje kućanskih
              aparata i bijele tehnike
            </p>
          </div>
        </div>

        <div class="item-box xl:m-5 h-80">
          <div
            class="
              self-center
              flex flex-col
              justify-center
              items-center
              gap-2
              xl:p-10
            "
          >
            <i class="fas fa-users text-5xl"></i>

            <h3 class="font-inter font-bold 2xl:text-3xl xl:text-2xl text-xl">
              Velik broj vjernih korisnika
            </h3>
            <hr class="border-gray-900 w-full" />
            <p>
              Kroz godine poštenog i kvalitetnog rada stekli smo povjerenje
              velikog broja cijenjenih korisnika usluga
            </p>
          </div>
        </div>

        <div class="item-box xl:m-5 h-80">
          <div
            class="
              self-center
              flex flex-col
              justify-center
              items-center
              gap-2
              xl:p-10
            "
          >
            <i class="fas fa-star text-5xl"></i>

            <h3 class="font-inter font-bold 2xl:text-3xl xl:text-2xl text-xl">
              Veliki izbor dijelova
            </h3>
            <hr class="border-gray-900 w-full" />
            <p>
              Kod servisiranja koristimo kvalitetne i orginalne rezervne
              dijelove kako bi Vaš uređaj radio što bolje
            </p>
          </div>
        </div>

        <div class="item-box xl:m-5 h-80">
          <div
            class="
              self-center
              flex flex-col
              justify-center
              items-center
              gap-2
              xl:p-10
            "
          >
            <i class="fas fa-certificate text-5xl"></i>

            <h3 class="font-inter font-bold 2xl:text-3xl xl:text-2xl text-xl">
              Konstantno unapređivanje
            </h3>
            <hr class="border-gray-900 w-full" />
            <p>
              Redovito pohađamo servisne treninge kako bismo pratili najnovije
              trendove u proizvodnji aparata bijele tehnike i povečali kvalitetu
              usluga.
            </p>
          </div>
        </div>
      </div>
      <div>
        <router-link to="/o-nama">
          <button class="btn-primary-front">Više o nama</button>
        </router-link>
      </div>
    </div>
  </div>

  <div class="flex flex-col justify-center xl:px-6 px-2 py-2 h-auto bg-white">
    <div
      class="
        min-h-full
        h-auto
        self-center
        p-5
        xl:p-16
        text-gray-900
        leading-relaxed
        font-inter
        xl:text-lg
        flex flex-col
      "
    >
      <div class="text-center my-10 xl:text-3xl text-xl font-bold">
        <h3>Ovlašteni smo servis grupe Electrolux za njihove robne marke</h3>
      </div>
      <div
        class="
          grid grid-cols-2
          xl:grid-cols-3
          gap-5
          lg:w-1/2
          md:w-3/4
          self-center
        "
      >
        <div class="item-box">
          <img
            src="/images/brands/electrolux.png"
            class="h-max"
            alt="Logo tvrtke electrolux"
          />
        </div>
        <div class="item-box">
          <img src="/images/brands/zanussi.png" alt="Logo tvrtke zanussi" />
        </div>
        <div class="item-box">
          <img src="/images/brands/aeg.png" alt="Logo tvrtke aeg" />
        </div>
        <div class="item-box">
          <img src="/images/brands/leonard.png" alt="Logo tvrtke leonard" />
        </div>
        <div class="item-box">
          <img src="/images/brands/progress.png" alt="Logo tvrtke progress" />
        </div>
        <div class="item-box">
          <img src="/images/brands/ikea.png" alt="Logo tvrtke ikea" />
        </div>
        <div class="item-box">
          <img src="/images/brands/rex.png" alt="Logo tvrtke rex" />
        </div>
        <div class="item-box">
          <img src="/images/brands/juno.png" alt="Logo tvrtke juno" />
        </div>
        <div class="item-box">
          <img src="/images/brands/zanker.png" alt="Logo tvrtke zanker" />
        </div>
        <div class="item-box">
          <img src="/images/brands/zoppas.png" alt="Logo tvrtke zoppas" />
        </div>
        <div class="item-box">
          <img src="/images/brands/privileg.png" alt="Logo tvrtke privileg" />
        </div>
      </div>
    </div>
  </div>
  <div
    class="
      flex flex-col
      h-full
      bg-gradient-to-tl
      from-black
      to-gray-800
      relative
      justify-evenly
    "
    id="reviews"
  >
    <div class="self-center">
      <h3
        class="
          text-white
          xl:text-5xl
          lg:text-3xl
          text-2xl
          font-inter font-bold
          relative
        "
      >
        Što naši kupci misle o nama
      </h3>
    </div>
    <ul class="flex items-center overflow-hidden">
      <Recenzija
        image="https://lh3.googleusercontent.com/a-/AOh14GgLtXQdet6rwKnaCeCl-V2nKwSiMkSMVl_iV9u1Xg=s40-c0x00000000-cc-rp"
        name="Karlo SIgurin"
        text="Super su. Najveće preporuke!"
        stars="5"
      />
      <Recenzija
        image="https://lh3.googleusercontent.com/a-/AOh14Gj4mPQ9g2-5EyPjIZffXOlqZtBHj93mkiuXNiKY7A=s40-c0x00000000-cc-rp-ba4"
        name="Ivica Horžić"
        stars="5"
      />
      <Recenzija
        image="https://lh3.googleusercontent.com/a/AATXAJwvHGG7gdSLodZFOydWJ2KlRh-Ma2D5-0dGgh2T=s40-c0x00000000-cc-rp-mo"
        name="Marko Fučkan"
        text="Sve pohvale.Preporučam"
        stars="5"
      />
      <Recenzija
        image="https://lh3.googleusercontent.com/a/AATXAJzIUxfeIxt2o2DWljnI8WtItgg6Nx0VlzBwzP_S=s40-c0x00000000-cc-rp-mo"
        name="Tomislav P"
        stars="4"
      />
      <Recenzija
        image="https://lh3.googleusercontent.com/a-/AOh14GjxuqAh91EetQ2AxGtAhNtsorSDtDg-aZoJSFtHwg=s40-c0x00000000-cc-rp"
        name="Barbara Sigurin"
        text="Pozitivno: Brzina reakcije, Profesionalnost"
        stars="5"
      />
      <Recenzija
        image="https://lh3.googleusercontent.com/a/AATXAJz-iN7Ihm3KnmvVH6tqXP4q2-rkuGMOy7PwbZg=s40-c0x00000000-cc-rp-mo"
        name="Bruno Kelenc"
        stars="5"
      />
      <Recenzija
        image="https://lh3.googleusercontent.com/a/AATXAJxzKhOqP3tzzSAFB3tKhDs7LN3S1GX4KxJh6kjI=s40-c0x00000000-cc-rp-mo"
        name="Dubravko Srpak"
        stars="5"
      />
    </ul>
  </div>

  <div
    class="
      flex flex-col
      justify-center
      xl:px-6
      px-2
      xl:py-15
      py-10
      h-auto
      min-h-full
      bg-white
    "
  >
    <div
      class="
        flex flex-col
        justify-center
        items-center
        xl:w-2/3
        md:w-3/4
        w-full
        px-2
        xl:gap-10
        gap-5
        self-center
      "
    >
      <h2
        class="
          xl:text-6xl
          text-gray-900 text-transparent text-4xl
          font-inter font-bold
        "
      >
        5 godina garancije
      </h2>
      <h3 class="text-gray-900 xl:text-3xl text-base font-inter">
        (2 godine redovne + 3 godine dodatne garancije)
      </h3>
      <hr class="border-gray-900 w-full" />
      <p class="font-inter xl:text-lg text-base text-center">
        U određenim promotivnim periodima možete ostvariti 5 godina garancije (2
        godine redovne + 3 godine dodatne garancije) za aparate marke
        <strong>Electrolux</strong> i <strong>AEG</strong> uz online prijavu. Za
        više informacija odaberite željenu marku.
      </p>
      <hr class="border-gray-900 w-full" />
      <div class="flex lg:flex-row flex-col self-center gap-5">
        <a
          href="https://www.electrolux.hr/5-godina-garancije/"
          class="h-1/3 self-center item-box"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/elec5gg.png" alt="5 godina garancije electrolux" />
        </a>
        <a
          href="https://www.electrolux.hr/5-godina-garancije/"
          class="h-1/3 self-center item-box"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/zanussi5gg.png" alt="5 godina garancije zanussi" />
        </a>
        <a
          href="https://www.aeg.hr/5-godina-garancije/"
          class="h-1/3 self-center item-box"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/aeg5gg.png" alt="5 godina garancije aeg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Recenzija from "@/components/Recenzija.vue";

export default {
  components: {
    Recenzija,
  },

  methods: {
    gotosection(id) {
      var my_element = document.getElementById(id);

      my_element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>
