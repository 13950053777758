import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import e404 from '@/views/errors/e404.vue';
import Kvar from '@/views/Kvar.vue';
import About from '@/views/About.vue';
import Kontakt from '@/views/Kontakt.vue';
import Podrska from '@/views/Podrska.vue';


const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/prijava-kvara',
		name: 'Kvar',
		component: Kvar,
		meta: { title: "Prijava kvara" }
	},
	{
		path: '/o-nama',
		name: 'About',
		component: About,
		meta: { title: "O nama" }

	},
	{
		path: '/kontakt',
		name: 'Kontakt',
		component: Kontakt,
		meta: { title: "Kontakt" }

	},
	{
		path: '/servisna-podrska',
		name: 'Podrska',
		component: Podrska,
		meta: { title: "Servisna podrška" }

	},
	{
		path: '/:catchAll(.*)',
		name: '404',
		component: e404,
		meta: { title: "404 - Stranica ne postoji!" }

	},

];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior(to, from) {
		return { x: 0, y: 0 };
	},
	routes,
});

const DEFAULT_TITLE = 'Elektroservis Panjkrc';

router.afterEach((to, from) => {
	document.title = to.meta.title ? `${to.meta.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;

});



export default router;
