<template>
  <li class="h-auto xl:w-1/3 lg:w-1/2 md:w-3/4 w-full flex-none pl-10 font-inter font-bold slide">
    <div v-if="text" class="flex flex-col w-full h-full bg-white rounded-2xl">
      <div class="h-auto flex flex-col p-10 gap-4">
        <i class="fas fa-quote-right xl:text-5xl lg:text-4xl md:text-3xl text-2xl"></i>
        <p class="lg:text-2xl md:text-xl text-lg ">{{ text }}</p>
      </div>
      <div
        class="bg-gradient-to-tl from-gray-400 to-gray-200 h-2/6 rounded-b-2xl"
      >
        <div class="h-full flex items-center p-5">
          <img
            class="transform scale-125 ml-5 border-2 border-gray-900 rounded-full"
            :src="image"
			alt="Slika profila"
          />
          <div class="m-5 text-gray-900 xl:text-xl lg:text-lg md:text-base text-sm">
            <p>{{ name }}</p>
            <i v-for="n in Number(stars)" class="fas fa-star text-gray-900"></i>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="flex flex-col w-full h-full bg-white rounded-2xl">
      <div class="h-auto flex flex-row justify-center xl:p-20 p-11 xl:gap-4 gap-1">
        <i v-for="n in Number(stars)" class="fas fa-star xl:text-5xl lg:text-4xl md:text-3xl  text-2xl text-gray-900"></i>
      </div>
      <div
        class="bg-gradient-to-tl from-gray-400 to-gray-200 h-2/6 rounded-b-2xl"
      >
        <div class="h-full flex items-center p-5">
          <img
            class="transform scale-125 ml-5 border-2 border-gray-900 rounded-full"
            :src="image"
			alt="Slika profila"
          />
          <div class="m-5 text-gray-900 text-xl">
            <p>{{ name }}</p>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ["image", "name", "stars", "text"],
};
</script>

<style>
.slide:nth-of-type(1) {
  animation: slide_keyframe1 40s linear infinite;
}
.slide:nth-of-type(2) {
  animation: slide_keyframe2 40s linear infinite;
}
.slide:nth-of-type(3) {
  animation: slide_keyframe3 40s linear infinite;
}
.slide:nth-of-type(4) {
  animation: slide_keyframe4 40s linear infinite;
}
.slide:nth-of-type(5) {
  animation: slide_keyframe5 40s linear infinite;
}
.slide:nth-of-type(6) {
  animation: slide_keyframe6 40s linear infinite;
}
.slide:nth-of-type(7) {
  animation: slide_keyframe7 40s linear infinite;
}

@keyframes slide_keyframe1 {
  0% {
    transform: translateX(0%);
  }
  14.285% {
    transform: translateX(-100%);
  }
  14.286% {
    transform: translateX(600%);
  }

  100% {
    transform: translateX(0%);
  }
}
@keyframes slide_keyframe2 {
  0% {
    transform: translateX(0%);
  }
  28.57% {
    transform: translateX(-200%);
  }
  28.571% {
    transform: translateX(500%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide_keyframe3 {
  0% {
    transform: translateX(0%);
  }
  42.856% {
    transform: translateX(-300%);
  }
  42.857% {
    transform: translateX(400%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide_keyframe4 {
  0% {
    transform: translateX(0%);
  }
  57.142% {
    transform: translateX(-400%);
  }
  57.143% {
    transform: translateX(300%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide_keyframe5 {
  0% {
    transform: translateX(0%);
  }
  71.428% {
    transform: translateX(-500%);
  }
  71.429% {
    transform: translateX(200%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide_keyframe6 {
  0% {
    transform: translateX(0%);
  }
  85.714% {
    transform: translateX(-600%);
  }
  85.715% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}
@keyframes slide_keyframe7 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-700%);
  }
}
</style>