<template>
  <div class="flex flex-col h-screen relative">
    <div class="w-screen sticky top-0 z-50">
      <Nav />
      <hr />
    </div>
    <div class="h-full overflow-y-auto snap-parent" id="content">
      <router-view />
	  <hr>
      <Footer />
    </div>
  </div>

  <!-- <div class="flex flex-col h-screen">
    <div class="h-full overflow-y-auto snap-parent" id="content">
      <div class="sticky top-0 z-50">
        <Nav />
        <hr />
      </div>
      <router-view />
      <Footer />
    </div>
  </div> -->
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";

export default {
  setup() {},
  components: {
    Nav,
    Footer,
  },
};
</script>



