<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-white" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 xl:px-8 relative">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="
              inline-flex
              items-center
              justify-center
              p-2
              rounded-md
              text-gray-400
              hover:text-white
              hover:bg-gray-700
              focus:outline-none
              focus:ring-2 focus:ring-inset focus:ring-white
            "
			id="closeButton"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div
          class="
            flex-1 flex
            items-center
            justify-center
            sm:items-stretch
            sm:justify-start
          "
        >
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/">
              <p
                class="
                  block
                  xl:hidden
                  h-8
                  w-auto
                  font-inter font-bold
                  italic
                  text-3xl
                  select-none
                "
              >
                ESP
              </p>
            </router-link>
            <router-link to="/">
              <p
                class="
                  hidden
                  xl:block
                  h-8
                  w-auto
                  font-inter font-bold
                  italic
                  text-3xl
                "
              >
                ESP
              </p>
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.href == $route.path
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-900 hover:bg-gray-700 hover:text-white',
                  'px-3 py-2 rounded-md text-sm font-medium',
                ]"
                :aria-current="item.href == $route.path ? 'page' : undefined"
                >{{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
        <a
          href="https://www.facebook.com/ElektroservisPanjkrc/"
          target="_blank"
          rel="noopener noreferrer"
          ><div class="hidden sm:block"><i class="fab fa-facebook"></i></div
        ></a>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden absolute w-full bg-white">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <router-link
          v-for="item in navigation"
          :key="item.name"
          :to="item.href"
          :class="[
            item.href == $route.path
              ? 'bg-gray-900 text-white'
              : 'text-gray-900 hover:bg-gray-700 hover:text-white',
            'block px-3 py-2 rounded-md text-base font-medium',
          ]"
          :aria-current="item.href == $route.path ? 'page' : undefined"
          @click="doClose(close)"
          >{{ item.name }}
        </router-link>
        <div class="p-3">
          <hr />
        </div>
        <a
          href="https://www.facebook.com/ElektroservisPanjkrc/"
          target="_blank"
          rel="noopener noreferrer"
          ><div class="m-3"><i class="fab fa-facebook"></i></div
        ></a>
      </div>
      <hr />
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

const navigation = [
  { name: "Početna", href: "/" },
  { name: "O nama", href: "/o-nama" },
  { name: "Kontakt", href: "/kontakt" },
  { name: "Servisna podrška", href: "/servisna-podrska" },
  //   { name: "Shop", href: "/shop" },
];

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
  },
  setup() {
    const open = ref(false);

    const doClose = (close) => {
      document.getElementById("closeButton").click()
    };

    return {
      navigation,
      open,
      doClose,
    };
  },
};
</script>