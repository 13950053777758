<template>
  <div class="flex justify-center h-auto w-full">
    <div
      class="
        xl:p-10
        py-2
        h-full
        xl:w-2/3
		md:w-3/4
		w-full
        grid
        xl:grid-cols-2 xl:grid-rows-1
        grid-rows-2
        gap-10
      "
    >
      <div
        class="
          flex flex-col
          justify-center
          items-center
          xl:text-3xl
          text-2xl
          gap-10
          h-half
		  w-full
        "
      >
        <p class="text-right">Kontaktirajte nas</p>
        <table>
          <tbody>
            <tr>
              <td class="font-bold">Telefon:</td>
              <td class="hover:underline">
                <a href="tel:012060137">01/2060 137</a>
              </td>
            </tr>
            <tr>
              <td class="font-bold">Adresa:</td>
              <td class="hover:underline">
                <a
                  href="https://g.page/ElektroservisPanjkrc"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Obrež Zelinski 17</a
                >
              </td>
            </tr>
            <tr>
              <td class="font-bold">E-Mail:</td>
              <td class="hover:underline">
                <a href="mailto:servis@esip.hr">servis@esip.hr</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="
          bg-white
          xl:w-full
          xl:h-2/3
          w-full
          h-full
          self-center
          justify-self-center
        "
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d693.7218827384004!2d16.260323329257265!3d45.933547631422236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47667306902f6e49%3A0xc8d277d0600e93fc!2sElektroservis%20Panjkrc!5e0!3m2!1shr!2shr!4v1622633431895!5m2!1shr!2shr"
          allowfullscreen="true"
          loading="lazy"
          class="h-full w-full"
        ></iframe>
      </div>

      <div
        class="
          flex flex-col
          justify-center
          items-center
          xl:text-3xl
          text-2xl
          gap-10
          xl:col-span-2
		  bg-gray-50
		  py-10
		  rounded-3xl
        "
      >
        <p>Pošaljite nam poruku</p>
        <div class="w-full h-full">
          <form
            class="grid grid-flow-row grid-cols-2 xl:px-20 xl:py-5 p-5 gap-4"
            id="kvar-form"
            @submit.prevent="onSubmit"
          >
            <div class="col-span-2 xl:col-span-1 w-full">
              <p class="font-thin text-gray-900 text-lg">Puno ime*</p>
              <input
                v-model="ime"
                type="text"
                name="ime"
                id="ime"
                placeholder="Ime i Prezime"
                class="
                  bg-gray-200
                  focus:ring-gray-900
                  focus:bg-white
                  focus:shadow-md
                  border-none
                  rounded-lg
                  w-full
                  xl:w-2/3
                "
                required
              />
            </div>

            <div class="col-span-2 xl:col-span-1 w-full">
              <p class="font-thin text-gray-900 text-lg">Telefon</p>
              <input
                v-model="telefon"
                type="tel"
                name="telefon"
                id="telefon"
                placeholder="+38598123456"
                class="
                  bg-gray-200
                  focus:ring-gray-900
                  focus:bg-white
                  focus:shadow-md
                  border-none
                  rounded-lg
                  w-full
                  xl:w-2/3
                "
              />
            </div>

            <div class="col-span-2 xl:col-span-1 w-full">
              <p class="font-thin text-gray-900 text-lg">Vaš E-Mail</p>
              <input
                v-model="email"
                type="email"
                name="email"
                id="email"
                placeholder="servis@esip.hr"
                class="
                  bg-gray-200
                  focus:ring-gray-900
                  focus:bg-white
                  focus:shadow-md
                  border-none
                  rounded-lg
                  w-full
                  xl:w-2/3
                "
              />
            </div>
            <div class="col-span-2 w-full">
              <p class="font-thin text-gray-900 text-lg">Poruka*</p>
              <textarea
                v-model="poruka"
                class="
                  bg-gray-200
                  focus:ring-gray-900
                  focus:bg-white
                  focus:shadow-md
                  border-none
                  rounded-lg
                  w-full
                  xl:w-5/6
                "
                name="poruka"
                id="poruka"
                cols="30"
                rows="10"
                required
              ></textarea>
            </div>

            <recaptcha ref="recaptcha" @verify="submit"></recaptcha>

            <div class="col-span-2 w-full">
              <button
                class="btn-secondary-front"
                type="submit"
                :disabled="prijavljeno"
              >
                Pošalji
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <Dialog ref="dialog_success" button="OK" title="Poruka poslana!">
    <p>Poruka uspješno poslana!</p>
  </Dialog>
  <Dialog ref="dialog_fail" button="OK" title="Poruka nije uspješno poslana!">
    <p>Nešto nije u redu.</p>
    <p>Molimo Vas pokušajte ponovno.</p>
  </Dialog>
  <Dialog ref="dialog_submiting" title="Slanje poruke u tijeku...">
    <p>Molimo prićekajte trenutak.</p>
  </Dialog>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
import Dialog from "@/components/Dialog.vue";
import axios from "axios";

var url = `https://${process.env.VUE_APP_BASE_URL}/api/v1/mail/kontakt`;

export default {
  components: {
    Recaptcha,
    Dialog,
  },

  data() {
    return {
      ime: "",
      telefon: "",
      email: "",
      poruka: "",
      rcapt_sig_key: "6LcEJwwbAAAAAGwR1tjcTvGwtO01OG3FDXLwD46y",
      prijavljeno: false,
      p: false,
    };
  },

  methods: {
    onSubmit() {
      this.$refs.dialog_submiting.openModal();
      this.executeRecaptcha();
    },

    async submit(response) {
      axios({
        url: url,
        data: {
          form_data: {
            ime: this.ime,
            telefon: this.telefon,
            email: this.email,
            poruka: this.poruka,
          },
          recaptcha_response: response,
        },
        method: "POST",
      })
        .then((response) => {
          this.prijavljeno = true;
          this.$refs.dialog_submiting.closeModal();
          this.$refs.dialog_success.openModal();
        })
        .catch((error) => {
          this.$refs.dialog_submiting.closeModal();
          this.$refs.dialog_fail.openModal();
        });
    },
    // execute the recaptcha widget
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>