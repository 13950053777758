<template>
  <div class="flex flex-col justify-center xl:px-6 px-2 py-2 min-h-full h-auto">
    <div
      class="
        xl:w-2/3
        xl:h-4/5
        md:w-3/4
        w-full
        h-full
        border-8 border-none
        rounded-3xl
        self-center
        relative
      "
    >
      <div
        class="
          min-h-full
          self-center
          p-5
          xl:p-16
          text-gray-900
          leading-relaxed
          font-inter
          xl:text-lg
        "
      >
        <h1 class="font-inter font-bold text-3xl my-5">O nama</h1>

        <hr />
        <div class="grid grid-cols-4 xl:grid-cols-6 my-5 gap-2 text-center">
          <div
            class="
              flex flex-col
              justify-center
              rounded-lg
              bg-gray-900
              h-full
              text-2xl
              xl:text-4xl
            "
          >
            <i class="fas fa-wrench self-center text-white"></i>
          </div>
          <div
            class="
              border-2 border-gray-900
              rounded-lg
              p-3
              xl:p-5
              col-span-3
              xl:col-span-5
            "
          >
            <p class="">
              Bavimo se prodajom, montažom i servisiranjem bijele tehnike,
              kućanskih aparata i rashladnih uređaja.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-4 xl:grid-cols-6 my-5 gap-2 text-center">
          <div
            class="
              flex flex-col
              justify-center
              rounded-lg
              bg-gray-900
              h-full
              text-2xl
              xl:text-4xl
            "
          >
            <i class="fas fa-puzzle-piece self-center text-white"></i>
          </div>
          <div
            class="
              border-2 border-gray-900
              rounded-lg
              p-3
              xl:p-5
              col-span-3
              xl:col-span-5
            "
          >
            <p class="">
              Na skladištu imamo veliki izbor rezervnih dijelova za bijelu
              tehniku i rashladne uređaje.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-4 xl:grid-cols-6 my-5 gap-2 text-center">
          <div
            class="
              flex flex-col
              justify-center
              rounded-lg
              bg-gray-900
              h-full
              text-2xl
              xl:text-4xl
            "
          >
            <i class="far fa-smile self-center text-white"></i>
          </div>
          <div
            class="
              border-2 border-gray-900
              rounded-lg
              p-3
              xl:p-5
              col-span-3
              xl:col-span-5
            "
          >
            <p class="">
              Naše dugogodišnje iskustvo,stručnost, profesionalnost i
              kontinuirano usavršavanje razlog su zadovoljstva velikog broja
              naših cijenjenih korisnika usluga
            </p>
          </div>
        </div>

        <div class="grid grid-cols-4 xl:grid-cols-6 my-5 gap-2 text-center">
          <div
            class="
              flex flex-col
              justify-center
              rounded-lg
              bg-gray-900
              h-full
              text-2xl
              xl:text-4xl
            "
          >
            <i class="fas fa-certificate self-center text-white"></i>
          </div>
          <div
            class="
              border-2 border-gray-900
              rounded-lg
              p-3
              xl:p-5
              col-span-3
              xl:col-span-5
            "
          >
            <p class="">
              Redovito pohađamo servisne treninge kako bismo pratili najnovije
              trendove u proizvodnji aparata bijele tehnike i povečali kvalitetu
              usluga.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-4 xl:grid-cols-6 my-5 gap-2 text-center">
          <div
            class="
              flex flex-col
              justify-center
              rounded-lg
              bg-gray-900
              h-full
              text-2xl
              xl:text-4xl
            "
          >
            <i class="fas fa-star self-center text-white"></i>
          </div>
          <div
            class="
              border-2 border-gray-900
              rounded-lg
              p-3
              xl:p-5
              col-span-3
              xl:col-span-5
            "
          >
            <p class="">
              Kod servisiranja koristimo kvalitetne i orginalne rezervne
              dijelove.
            </p>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div
      class="
        xl:w-2/3
        xl:h-4/5
        w-full
        h-full
        border-8 border-none
        rounded-3xl
        self-center
        relative
      "
    >
      <div
        class="
          min-h-full
          self-center
          p-5
          xl:p-16
          text-gray-900
          leading-relaxed
          font-inter
          xl:text-lg
          flex flex-col
        "
      >
        <div class="text-center my-10 text-xl font-bold">
          <h2>
            Servis i održavanje možemo Vam ponuditi za sljedeće grupe uređaja
          </h2>
        </div>
        <div
          class="
            grid grid-cols-2
            xl:grid-cols-3
            gap-5
            xl:w-2/3
            self-center
            text-center
          "
        >
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/fridge.svg" class="w-20 p-3 self-center" alt="Ikona hladnjaka" />
              <p>Hladnjaci</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/freezer.svg" class="w-20 p-3 self-center" alt="Ikona zamrzivača" />
              <p>Zamrzivači</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/dishwasher.svg" class="w-20 p-3 self-center" alt="Ikona perilice posuđa" />
              <p>Perilice posuđa</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/laundry.svg" class="w-20 p-3 self-center" alt="Ikona perilice rublja" />
              <p>Perilice rublja</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/dryer.svg" class="w-20 p-3 self-center" alt="Ikona sušilice rublja" />
              <p>Sušilice rublja</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/oven.svg" class="w-20 p-3 self-center" alt="Ikona pećnice" />
              <p>Pećnice</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/microwave.svg" class="w-20 p-3 self-center" alt="Ikona mikrovalne pećnice" />
              <p>Mikrovalne pećnice</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/vacuum.svg" class="w-20 p-3 self-center" alt="Ikona usisavača" />
              <p>Usisavači</p>
            </div>
          </div>
          <div class="item-box">
            <div class="self-center flex flex-col">
              <img src="/icons/more.svg" class="w-20 p-3 self-center" alt="Ikona dodatno" />
              <p>I mnoge druge</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div
      class="
        xl:w-2/3
        xl:h-4/5
        md:w-3/4
        w-full
        h-full
        border-8 border-none
        rounded-3xl
        self-center
        relative
      "
    >
      <div
        class="
          min-h-full
          self-center
          p-5
          xl:p-16
          text-gray-900
          leading-relaxed
          font-inter
          xl:text-lg
          flex flex-col
        "
      >
        <div class="item-box xl:w-1/4 self-center">
          <img src="/images/electrolux-service-partner.png" alt="Logo 'Electrolux service partner'" />
        </div>

        <div class="text-center my-10 text-xl font-bold">
          <h2>Ovlašteni smo servis grupe Electrolux za njihove robne marke</h2>
        </div>

        <div
          class="
            grid grid-cols-2
            xl:grid-cols-3
            gap-5
            xl:w-2/3
            lg:w-3/4
            self-center
          "
        >
          <div class="item-box">
            <img
              src="/images/brands/electrolux.png"
              class="h-max"
              alt="Logo tvrtke electrolux"
            />
          </div>
          <div class="item-box">
            <img src="/images/brands/zanussi.png" alt="Logo tvrtke zanussi" />
          </div>
          <div class="item-box">
            <img src="/images/brands/aeg.png" alt="Logo tvrtke aeg" />
          </div>
          <div class="item-box">
            <img src="/images/brands/leonard.png" alt="Logo tvrtke leonard" />
          </div>
          <div class="item-box">
            <img src="/images/brands/progress.png" alt="Logo tvrtke progress" />
          </div>
          <div class="item-box">
            <img src="/images/brands/ikea.png" alt="Logo tvrtke ikea" />
          </div>
          <div class="item-box">
            <img src="/images/brands/rex.png" alt="Logo tvrtke rex" />
          </div>
          <div class="item-box">
            <img src="/images/brands/juno.png" alt="Logo tvrtke juno" />
          </div>
          <div class="item-box">
            <img src="/images/brands/zanker.png" alt="Logo tvrtke zanker" />
          </div>
          <div class="item-box">
            <img src="/images/brands/zoppas.png" alt="Logo tvrtke zoppas" />
          </div>
          <div class="item-box">
            <img src="/images/brands/privileg.png" alt="Logo tvrtke privileg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>