<template>
  <div
    class="
      xl:p-10
      p-5
      h-auto
      bg-gray-900
      text-white
      flex flex-col
      justify-center
      xl:items-center
      font-inter
    "
  >
    <div class="h-full xl:w-2/3 md:w-3/4 my-3 md:self-center">
      <div
        class="
          h-full
          w-full
          flex flex-col
          md:flex-row
          flex-wrap
          justify-between
		  lg:gap-5
          mb-4
        "
      >
        <div class="mb-3">
          <p class="text-3xl xl:text-5xl font-bold italic my-1">ESP</p>
          <p class="text-lg xl:text-xl font-bold">Elektroservis Panjkrc</p>
          <p class="text-base xl:text-lg mb-2">Servis kućanskih aparata</p>
          <div class="text-gray-300">
            <p class="text-sm xl:text-base">Obrež Zelinski 17</p>
            <p class="text-sm xl:text-base">10380, Sveti Ivan Zelina</p>
          </div>
        </div>
        <div class="mb-3">
          <p class="text-base font-bold xl:text-xl my-3">Glavni izbornik</p>
          <div class="text-sm text-gray-300">
            <router-link v-for="n in navigation" :key="n.name" :to="n.href">
              <p class="hover:underline">{{ n.name }}</p>
            </router-link>
          </div>
        </div>
        <div class="mb-3">
          <p class="text-base font-bold xl:text-xl my-3">Kontakt</p>
          <div class="text-sm text-gray-300">
            <table>
              <tbody>
                <tr>
                  <td class="font-bold">Telefon:</td>
                  <td class="hover:underline">
                    <a href="tel:012060137">01/2060 137</a>
                  </td>
                </tr>
                <tr>
                  <td class="font-bold">Adresa:</td>
                  <td class="hover:underline">
                    <a
                      href="https://g.page/ElektroservisPanjkrc"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Obrež Zelinski 17</a
                    >
                  </td>
                </tr>
                <tr>
                  <td class="font-bold">E-Mail:</td>
                  <td class="hover:underline">
                    <a href="mailto:servis@esip.hr">servis@esip.hr</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mb-3">
          <p class="text-base font-bold xl:text-xl my-3">Radno vrijeme</p>
          <div class="text-sm text-gray-300">
            <table>
              <tbody>
                <tr>
                  <td class="font-bold">Pon - Pet:</td>
                  <td>
                    <p>8:00 - 18:00</p>
                  </td>
                </tr>
                <tr>
                  <td class="font-bold">Subota:</td>
                  <td>
                    <p>8:00 - 13:00</p>
                  </td>
                </tr>
                <tr>
                  <td class="font-bold">Nedjeljom i blagdanima:</td>
                  <td>
                    <p class="font-bold">ZATVORENO</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr />
      <p class="font-thin text-gray-300 text-xs xl:text-sm my-5">
        ©1990 - {{ year }} Elektroservis Panjkrc
      </p>
    </div>
  </div>
</template>

<script>
const navigation = [
  { name: "Početna", href: "/" },
  { name: "O nama", href: "/o-nama" },
  { name: "Kontakt", href: "/kontakt" },
  { name: "Servisna podrška", href: "/servisna-podrska" },
  { name: "Prijavi kvar", href: "/prijava-kvara" },
];
export default {
  computed: {
    year: () => {
      var d = new Date();
      return d.getFullYear();
    },
  },

  setup() {
    return {
      navigation,
    };
  },
};
</script>

<style>
</style> 

