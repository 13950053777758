<template>
  <div class="flex flex-col justify-center p-10 min-h-full h-auto text-center">
    <div
      class="
        flex flex-col
        justify-center
        items-center
        xl:w-2/3
		md:w-3/4
        w-full
        px-2
        xl:gap-10
        gap-5
        self-center
      "
    >
      <h1
        class="
          xl:text-4xl
          text-gray-900 text-transparent text-2xl
          font-inter font-bold
        "
      >
        Savjetnik za kvarove
      </h1>
      <h2 class="text-gray-900 xl:text-3xl text-base font-inter">
        Koristite naš Savjetnik za kvarove i riješite probleme s kojima se
        susrećete.
      </h2>
      <hr class="border-gray-900 w-full" />

      <p class="font-inter xl:text-2xl text-base font-bold">
        Koristite sustav za rješavanje problema pritiskom na logo proizvođača.
      </p>

      <div class="flex xl:flex-row flex-col self-center gap-5">
        <a
          href="https://www.electrolux.hr/support/"
          class="self-center item-box xl:w-1/3 md:w-3/4"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/brands/electrolux.png" alt="support electrolux" />
        </a>
        <a
          href="https://www.electrolux.hr/support/"
          class=" self-center item-box xl:w-1/3 md:w-3/4"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/brands/zanussi.png" alt="support zanussi" />
        </a>
        <a
          href="https://www.aeg.hr/support/"
          class="self-center item-box xl:w-1/3 md:w-3/4"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/brands/aeg.png" alt="support aeg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>